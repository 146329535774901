import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { useQuery, gql } from '@apollo/client'
import { media, theme } from '@dfds-ui/theme'
import { Spinner } from '@dfds-ui/react-components'
import { parseISO, format } from 'date-fns'
import { enUS } from 'date-fns/locale'

import { FlexCard } from '../Card'

const NASDAQ_PRICE_INFO_QUERY = gql`
  query NasdaqShareInfoQuery($companycode: String!) {
    chart: nasdaqShareInfo(companycode: $companycode) @client {
      Currency
      Bid
      Ask
      Change
      ChangePercent
      Last
      High
      Low
      Volume
      PreviousClose
      Date
      ISIN
      Symbol
      MarketName
      NoShares
      MarketCap
      Open
    }
  }
`

const formatNasdaqDisplayDate = (string) => {
  const formatted = format(parseISO(string), 'MMM d, yyyy HH:mm', {
    locale: enUS,
  })
  return `${formatted} CEST`
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.greaterThan('m')`
    flex-direction: row;
  `}
`

const StyledInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`

const StyledNumber = styled.span`
  font-size: 26px;
  line-height: 26px;
`

const StyledPostFix = styled.span`
  text-transform: uppercase;
  font-size: 14px;
`
const LoadSpinner = () => (
  <FlexCard width={{ md: 12 }}>
    <div
      css={css`
        text-align: center;
      `}
    >
      <Spinner />
    </div>
  </FlexCard>
)

const SharePriceBlock = (props) => {
  const {
    changeTodayTitle,
    latestTradeShownTitle,
    priceTitle,
    volumeTitle,
    volumeUnit,
  } = props
  const { data, loading, error } = useQuery(NASDAQ_PRICE_INFO_QUERY, {
    variables: { companycode: 'dk-dfds' },
    pollInterval: 10000,
  })
  if (loading) return <LoadSpinner />
  if (error) return <LoadSpinner />
  if (data) {
    return (
      <FlexCard>
        <StyledContainer>
          <div
            css={css`
              margin-bottom: 20px;

              ${media.greaterThan('m')`
                margin-bottom: 0;
              `}
            `}
          >
            <div>
              <strong>{priceTitle}</strong>
            </div>
            <StyledInfoWrapper>
              <StyledNumber>
                {parseFloat(data.chart.Last).toFixed(2)}
              </StyledNumber>
              <StyledPostFix>{data.chart.Currency}</StyledPostFix>
            </StyledInfoWrapper>
          </div>
          <div
            css={css`
              margin-bottom: 20px;

              ${media.greaterThan('m')`
                margin-bottom: 0;
              `}
            `}
          >
            <div>
              <strong>{changeTodayTitle}</strong>
            </div>
            <StyledInfoWrapper
              css={css`
                color: ${Math.sign(parseFloat(data.chart.ChangePercent)) === 1
                  ? theme.colors.status.success
                  : Math.sign(parseFloat(data.chart.ChangePercent)) === -1
                    ? theme.colors.status.alert
                    : ''};
              `}
            >
              <StyledNumber>
                {`${
                  Math.sign(parseFloat(data.chart.ChangePercent)) === 1
                    ? '+'
                    : Math.sign(parseFloat(data.chart.ChangePercent)) === -1
                      ? ''
                      : ''
                } ${parseFloat(data.chart.ChangePercent).toFixed(2)}`}
              </StyledNumber>
              <StyledPostFix>%</StyledPostFix>
            </StyledInfoWrapper>
          </div>
          <div
            css={css`
              margin-bottom: 20px;

              ${media.greaterThan('m')`
                margin-bottom: 0;
              `}
            `}
          >
            <div>
              <strong>{volumeTitle}</strong>
            </div>
            <StyledInfoWrapper>
              <StyledNumber>
                {parseInt(data.chart.Volume).toLocaleString('en')}
              </StyledNumber>
              <StyledPostFix>{volumeUnit}</StyledPostFix>
            </StyledInfoWrapper>
          </div>
          <div>
            <div>
              <strong>{latestTradeShownTitle}</strong>
            </div>
            <StyledInfoWrapper>
              {formatNasdaqDisplayDate(data.chart.Date)}
            </StyledInfoWrapper>
          </div>
        </StyledContainer>
      </FlexCard>
    )
  }
}

export const SharePriceBlockFragment = graphql`
  fragment SharePriceBlock on contentful_SharePriceBlock {
    __typename
    sys {
      id
    }
    changeTodayTitle
    latestTradeShownTitle
    priceTitle
    volumeTitle
    volumeUnit
  }
`

export default SharePriceBlock
